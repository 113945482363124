import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Wrapper, Button, Article } from "../components"
import PageProps from "../models/PageProps"
import Helmet from "react-helmet"
import config from "../../config/SiteConfig"
import { media } from "../utils/media"
import rgba from "polished/lib/color/rgba"
import darken from "polished/lib/color/darken"
import lighten from "polished/lib/color/lighten"
import { TextScroller } from "../components/TextScroller"
import Theme from "../../config/Theme"

const Homepage = styled.main`
    display: flex;
    height: 100vh;
    flex-direction: row;
    @media ${media.tablet} {
        height: 100%;
        flex-direction: column;
    }
    @media ${media.phone} {
        height: 100%;
        flex-direction: column;
    }
    button {
        margin-top: 15px;
    }
`

const ImageGrid = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/assets/image.jpg) no-repeat;
    background-size: cover;
    padding: 2rem 4rem;
    color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    h1 {
        color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    }
    animation: blur 1s infinite;
`

const GridRow: any = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props: any) =>
        props.background
            ? `linear-gradient(
      -185deg,
      ${rgba(darken(0.1, props.theme.colors.primary), 0)},
      ${rgba(lighten(0.1, props.theme.colors.grey.dark), 0)}), url(/assets/bg.jpg) no-repeat`
            : null};
    background-size: cover;
    padding: 2rem 4rem;
    color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    h1 {
        color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
    }
    @media ${media.tablet} {
        padding: 3rem 3rem;
    }
    @media ${media.phone} {
        padding: 2rem 1.5rem;
    }
`

const HomepageContent: any = styled.div`
    max-width: 30rem;
    text-align: ${(props: any) => (props.center ? "center" : "left")};

    h1, h2, h3, p {
        color: ${Theme.colors.white};
    }
`

export default class IndexPage extends React.Component<PageProps> {
    public render() {
        const { data } = this.props
        const { edges, totalCount } = data.allMarkdownRemark
        return (
            <Layout>
                <Wrapper fullWidth={true}>
                    <Helmet title={`Homepage | ${config.siteTitle}`} />
                    <Homepage>
                        <ImageGrid>
                            <HomepageContent center={true}>
                                {/* <img src={config.siteLogo} /> */}
                                <h1>Tyler Churchill</h1>
                                <p>
                                    I'm a <br />
                                    <b>
                                        <TextScroller messages={["Full Stack Developer", "Entrepreneur", "Team Player", "Mentor"]} />
                                    </b>
                                </p>

                                <Link to="/blog">
                                    <Button small>Blog</Button>
                                </Link>
                                <Link to="/collections">
                                    <Button small>Collections</Button>
                                </Link>
                            </HomepageContent>
                        </ImageGrid>
                        <GridRow>
                            <HomepageContent>
                                <h2>About Me</h2>
                                <p>
                                    Experienced with developing full stack web applications that support high performance and high
                                    availability.
                                </p>
                                <hr />
                                <h2>Latest Posts</h2>
                                {edges.map(post => (
                                    <Article
                                        title={post.node.frontmatter.title}
                                        date={post.node.frontmatter.date}
                                        excerpt={post.node.excerpt}
                                        timeToRead={post.node.timeToRead}
                                        slug={post.node.fields.slug}
                                        category={post.node.frontmatter.category}
                                        key={post.node.fields.slug}
                                    />
                                ))}
                                <p className={"textRight"}>
                                    <Link to={"/blog"}>All posts ({totalCount})</Link>
                                </p>
                            </HomepageContent>
                        </GridRow>
                    </Homepage>
                </Wrapper>
            </Layout>
        )
    }
}
export const IndexQuery = graphql`
    query {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "blog"}}}, sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD.MM.YYYY")
                        category
                    }
                    timeToRead
                }
            }
        }
    }
`
