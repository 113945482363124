import React from "react"

export const TextScroller = (props: any) => {
    const { messages } = props
    // Default to the first message passed
    const [messageIndex, setMessageIndex] = React.useState(0)
    const time = 2000
    React.useEffect(() => {
        // Move on to the next message every `n` milliseconds
        let timeout: any

        if (messageIndex == messages.length - 1) {
            timeout = setTimeout(() => setMessageIndex(0), time)
        }
        if (messageIndex < messages.length - 1) {
            timeout = setTimeout(() => setMessageIndex(messageIndex + 1), time)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [messages, messageIndex])

    return <>{messages[messageIndex]}</>
}
